import React, { FunctionComponent, ReactNode, SVGProps, useEffect } from 'react';
import styled from 'styled-components';
import { Stack, Flex } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Type definitions
interface IFeatureBoxProps {
  FeatureBoxDesktop: FunctionComponent<SVGProps<SVGSVGElement>>;
  children: ReactNode;
  height: number;
}

interface IFeatureBoxInfosProps {
  sub: string;
  color?: string;
  children: ReactNode;
}

interface IFeatureBoxContentsProps {
  children: ReactNode;
}

// Styled components
const Title = styled.h2`
  color: #fff;
  font-family: Pretendard;
  font-size: 48px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
      text-align: left;
  }
`;

const Details = styled.p`
  color: #f9f9f9;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 16px;

  }

  @media (max-width: 768px) {
    font-size: 15px;
      text-align: left;
  }
`;

const StyledSubText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  display: block;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const StyledSection = styled(motion.section)<{ height: number }>`
  width: 100%;
  max-width: 1482px;
  margin:0 auto;
  height: ${(props) => props.height}px;
  text-align: center;
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
    text-align: left;

  }
`;
const StyledBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;

  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  gap: 20px;

  margin: 0 auto;

  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 15px;
    padding: 0 15px;
  }
`;


const StyledFeatureBoxDesktop = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;
const StyledFeatureBoxMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    justify-content: center;
    align-items: center;

    position: relative; /* Ensure it's in the flow */
    z-index: 2; /* Ensure it sits above other content */
  }
`;

const Overlay = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

const StyledInfoContainer = styled(Stack)`
  width: 100%;
  gap: 12px;
  align-items: center;
  max-width: 1200px;
  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 15px;
  }
  @media (max-width: 1024px) {

  }
`;

const StyledContentsContainer = styled(Flex)`
  width: 100%;
  max-width: 1280px;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
  margin-top: 20px; /* Adjust spacing as necessary */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

  }
`;

// Components for Infos and Contents
const FeatureBoxInfos: React.FC<IFeatureBoxInfosProps> = ({ sub, children, color = '#C377FF' }) => (
  <StyledInfoContainer>
    <StyledSubText color={color}>{sub}</StyledSubText>
    <Stack gap="20px">{children}</Stack>
  </StyledInfoContainer>
);

const FeatureBoxContents: React.FC<IFeatureBoxContentsProps> = ({ children }) => (
  <StyledContentsContainer>{children}</StyledContentsContainer>
);

// Main FeatureBox component
const FeatureBox: React.FC<IFeatureBoxProps> & {
  Infos: React.FC<IFeatureBoxInfosProps>;
  Title: React.FC<{ children: ReactNode }>;
  Details: React.FC<{ children: ReactNode }>;
  Contents: React.FC<IFeatureBoxContentsProps>;
} = ({ FeatureBoxDesktop, children, height }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    controls.start(inView ? 'visible' : 'hidden');
  }, [controls, inView]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  return (
    <StyledSection ref={ref} initial="hidden" animate={controls} variants={fadeIn} height={height}>
      <StyledBox>
        <StyledFeatureBoxDesktop>
          <FeatureBoxDesktop />
          <Overlay>{children}</Overlay>
        </StyledFeatureBoxDesktop>
        <StyledFeatureBoxMobile>{children}</StyledFeatureBoxMobile>
      </StyledBox>
    </StyledSection>
  );
};

// Attach components
FeatureBox.Infos = FeatureBoxInfos;
FeatureBox.Title = Title;
FeatureBox.Details = Details;
FeatureBox.Contents = FeatureBoxContents;

export default FeatureBox;
