import React from 'react';
import styled from 'styled-components';

interface MainVisualProps {
  lang: 'EN' | 'KO';
}

const MainVisual: React.FC<MainVisualProps> = ({ lang }) => {
  const translations: Record<'EN' | 'KO', {
    title: string;
    description: string;
    contactUs: string;
  }> = {
    EN: {
      title: "ALL ON with XROOM. \n Dive into the Magic of 3D Storytelling!",
      description: "XR production, once reserved for media professionals, is now open to everyone with XROOM. Create high-quality content effortlessly, without complex setups or equipment. Maximize your creativity and deliver immersive videos with ease.",
      contactUs: "CONTACT US",
    },
    KO: {
      title: "XROOM과 함께 3D 스토리텔링의 \n 모든 것을 경험하세요!",
      description: "이제 방송 및 미디어 전문가들만의 특권이었던 XR 제작이 XROOM으로 누구에게나 열립니다. 복잡한 장비나 까다로운 설정 없이, 누구나 쉽게 고품질의 콘텐츠를 제작할 수 있는 혁신적인 기술을 경험해보세요. 공간의 제약을 넘어서는 XROOM으로, 당신의 창의력을 극대화하고 시청자들에게 몰입감 넘치는 영상을 제공할 수 있습니다.",
      contactUs: "문의하기",
    },
  };

  const { title, description, contactUs } = translations[lang];

  return (
    <MainVisualContainer>
      <MainVisualWrapper>
        <Logo src="/images/xroom_ces/xroom_last.png" alt="xroom logo" />
        <TextWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextWrapper>
        <ButtonWrapper>
          <StyledButton onClick={() => {
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSeGqhJwxOVZaxUx4-iH0LbGmNTpqHCer8-4ZhWuJlQypdg9qw/viewform',
            );
          }}>
            {contactUs}
          </StyledButton>
        </ButtonWrapper>
      </MainVisualWrapper>
    </MainVisualContainer>
  );
};

const MainVisualContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  margin-top: 100px;

  @media (max-width: 768px) {
    padding: 40px 10px;
    margin-top: 50px;
  }
`;

const MainVisualWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* 기본적으로 가운데 정렬 */
  gap: 20px;

  @media (max-width: 768px) {
    align-items: flex-start; /* 모바일 화면에서 왼쪽 정렬 */
    gap: 15px;
  }
`;

const Logo = styled.img`
  height: 30px;
  margin-top: 95px;

  @media (max-width: 1024px) {
    height: 25px;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    height: 20px;
    margin-top: 40px;
    margin-Left: 16px;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
  max-width: 1137px;

  @media (max-width: 1024px) {
    max-width: 900px;
  }

  @media (max-width: 768px) {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start; /* 모바일 화면에서 텍스트 왼쪽 정렬 */
    gap: 16px;
    margin: 16px;
  }
`;

const Title = styled.h1`
  color: #FFF;
  text-align: center;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  font-family: Pretendard;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 1024px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 32px;
    text-align: left; /* 모바일에서 제목 왼쪽 정렬 */
  }
`;

const Description = styled.p`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    text-align: left; /* 모바일에서 설명 왼쪽 정렬 */
  }
`;

const ButtonWrapper = styled.div`
  width: 168px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: flex-start; /* 모바일에서 버튼 왼쪽 정렬 */
    margin-Left: 16px;
  }
`;

const StyledButton = styled.button`
  width: 170px;
  padding: 10px 20px;
  background: linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%);
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.3s;
  color: #FFF;

  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export default MainVisual;
