import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

// Styled components
const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 255px auto 164px; // Top, horizontal center (auto), bottom spacing
  max-width: 700px; // Max width for alignment
  padding: 0 32px; // Horizontal padding
  
  @media (max-width: 1024px) {
    margin: 500px auto 164px; // Top, horizontal center (auto), bottom spacing
  }

  @media (max-width: 768px) {
    margin: 255px auto 164px; // Top, horizontal center (auto), bottom spacing
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
`;

const Image = styled.img`
  max-width: 674px; // Max width
  height: auto; // Maintain aspect ratio
  width: 100%; // Full width
  display: block; // Block element to prevent margin issues
`;

const DescriptionContainer = styled.div`
  width: 100%; // Full width of the container
`;

const Title = styled.h2`
  font-size: 1.5rem;

  @media (max-width: 1024px) {
    font-size: 1.25rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SubText = styled.p`
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const DescText = styled.p`
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 160%; /* 32px */

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ButtonContainer = styled.button<{ width?: string }>`
  width: ${(props) => props.width || '170px'};
  padding: 10px 20px;
  background: linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%);
  color: #000;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.3s;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

type IServiceItemCard = {
  imgName: string;
  title: string;
  sub: string;
  desc: string;
  children?: ReactElement<IServiceItemCard> | Array<ReactElement<IServiceItemCard>>;
};

type IServiceItemCardBtn = {
  btnName: ReactNode;
  width?: string;
  onClick: () => void;
};

const ServiceItemCardBtn: React.FC<IServiceItemCardBtn> = ({ btnName, width, onClick }) => {
  return (
    <ButtonContainer width={width} onClick={onClick}>
      {btnName}
    </ButtonContainer>
  );
};

const ServiceItemCard: React.FC<IServiceItemCard> & {
  Button: React.FC<IServiceItemCardBtn>;
} = ({ imgName, title, sub, desc, children }) => {
  return (
    <CardContainer>
      <InfoContainer>
        <Title>{title}</Title>
        <Image src={`images/xroom_ces/${imgName}.png`} alt={`${title} - ${imgName} image`} />
        <DescriptionContainer>
          <SubText>{sub}</SubText>
          <DescText>{desc}</DescText>
        </DescriptionContainer>
      </InfoContainer>
      {children}
    </CardContainer>
  );
};

ServiceItemCard.Button = ServiceItemCardBtn;

export default ServiceItemCard;
