import React, { useEffect, useState } from 'react';
import { Box, Spacer, Stack } from '@chakra-ui/react';
import styled from 'styled-components';
import FeatureBox from './common/FeatureBox';
import MainVisual from './common/MainVisual';
import Video from './common/Video';
import { ReactComponent as FeatureBoxDesktop1 } from '../../../static/xroom_ces/featurebox__desktop.svg';
import { ReactComponent as FeatureBoxDesktop2 } from '../../../static/xroom_ces/featurebox2__desktop.svg';
import ImgCard from './common/ImgCard';
import ServiceItemCard from './common/ServiceItemCard';
import HorizontalCard from './common/HorizontalCard';
import { translations, Language } from './translations'; // translations import
import Footer from './common/Footer';

// Styled Components

// Styled Components

const MainContainer = styled(Box)`
  width: 100%;

  background: top center / cover no-repeat url('/images/xroom_ces/landing_desktop.svg');
  padding-top: 72px; /* Header height */
  overflow: hidden; // Prevents horizontal scroll

  @media (max-width: 1024px) {
    width: 100vw; // Ensure full viewport width
    height: auto; // Allow height to adjust based on content
  }

  @media (max-width: 768px) {
    width: 100vw; // Ensure full viewport width
    height: auto; // Allow height to adjust based on content
  }
`;


const HeaderContainer = styled.header`
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const RightWrapperBox = styled.div`
  display: flex;
  margin: 20px;
  align-items: center;
  gap: 16px;
`;

const HeaderLink = styled.a`
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const LanguageToggleButton = styled.button`
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-family: Pretendard;
  margin: 0;
  margin-left: 28px;
  transition: color 0.3s ease, background-color 0.3s ease;
  text-decoration: underline;
`;

const Button = styled.div`
  padding: 10px 20px;
  background: linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%);
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.3s;
  text-align: center;
  font-family: Pretendard;
  font-weight: 700;
  line-height: normal;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const LastVisualContainer = styled.section<{ titleFontSize?: string; descFontSize?: string }>`

  background-color: #f9f9f9;
  text-align: center;
  display: flex;
  flex-direction: column; // Align items in a column
  align-items: center; // Center items horizontally

  background: 
  url('/images/xroom_ces/last_bg.svg') top center / cover no-repeat, 
  url('/images/xroom_ces/back2.png') top center / cover no-repeat;
  margin-top: 500px; // Default for Desktop
  padding: 0 0 370px 0 ;
  @media (max-width: 1024px) { // Tablet
    margin-top: 228px;
    padding: 40px;
  }
  
  @media (max-width: 768px) { // Mobile
    margin-top: 165px;
    padding: 40px;
  }

  h1 {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 54px;
    font-weight: 500;
    
    @media (max-width: 1024px) { // Tablet
      font-size: 36px;
    }
    
    @media (max-width: 768px) { // Mobile
      font-size: 28px;
    }
  }

  p {
    margin-top: 20px;
    max-width: 700px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    line-height: 160%; /* 32px */
    
    @media (max-width: 768px) { // Mobile
      font-size: 15px;
    }
  }
`;

const Logo = styled.img`
  height: 89px;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    height: 55px;
    margin-bottom: 32px;
  }

  @media (max-width: 768px) {
    height: 31px;
    margin-bottom: 20px;
  }
`;

const ContactButton = styled.button`
  padding: 10px 20px;
  background: linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%);
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.3s;
  text-align: center;
  font-family: Pretendard;
  font-weight: 700;
  line-height: normal;
  margin-top:20px;
  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const XroomSXSWDesktop: React.FC = () => {
  const [lang, setLang] = useState<Language>('EN');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Translations based on the current language
  const {
    featureSub,
    featureTitle,
    featureDetails,
    servicesub,
    serviceDesc,
    tryDemo,
    assetsSub,
    assetsTitle,
    assetsDetails,
    livestreamSub,
    livestreamTitle,
    livestreamDetails,
    interfaceSub,
    interfaceTitle,
    interfaceDetails,
    lastVisualTitle,
    lastVisualDesc,
    contactUs,
  } = translations[lang];

  // Toggle language function
  const toggleLanguage = () => {
    const newLang = lang === 'EN' ? 'KO' : 'EN';
    setLang(newLang);
    window.history.replaceState({}, '', `?lang=${newLang}`);
  };

  // Handle window resize to determine mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MainContainer>
      {/* Header Section */}
      <HeaderContainer>
        <FlexContainer>
          <img src="/images/XROOMlogo.svg" alt="xroom logo" width={111} height={22} />
          <RightWrapperBox>
            <HeaderLink href="https://store.steampowered.com/app/2616930/XROOM/">
              <img src="/images/Arrow_Down_MD.svg" alt="arrow down" />
              {lang === 'EN' ? 'Download' : '다운로드'}
            </HeaderLink>
            |
            <HeaderLink
              href={
                lang === 'EN'
                  ? 'https://teamsoulx.notion.site/XROOM-Guide-eb32ebb8970043a1a0512fcdf7b63fe9?pvs=4'
                  : 'https://teamsoulx.notion.site/XROOM-Guide-642e5dfc424f40779353ae3fd7cf6c5d?pvs=4'
              }
            >
              <img src="/images/Arrow_Up_Right_MD.svg" alt="arrow up" />
              {lang === 'EN' ? 'Guide' : '가이드'}
            </HeaderLink>
            <LanguageToggleButton onClick={toggleLanguage}>
              {lang === 'EN' ? 'KO' : 'EN'}
            </LanguageToggleButton>
          </RightWrapperBox>
        </FlexContainer>
      </HeaderContainer>

      {/* Main Content */}
      <MainVisual lang={lang} />
      <Video />

      {/* Feature Box 1 */}
      <FeatureBox FeatureBoxDesktop={FeatureBoxDesktop1} height={1034}>
        <FeatureBox.Infos sub={featureSub} color="#F1DEFF">
          <FeatureBox.Title>{featureTitle}</FeatureBox.Title>
          <FeatureBox.Details>{featureDetails}</FeatureBox.Details>
        </FeatureBox.Infos>
        <FeatureBox.Contents>
          {['feature1_img1.png', 'feature1_img2.png', 'feature1_img3.png', 'feature1_img4.png'].map((imgName, index) => (
            <ImgCard
              key={index}
              imgName={imgName}
              desc={
                lang === 'KO'
                  ? ['무한한 3D 오브젝트 및 환경', '실시간 제작 및 공유', 'AI 기반 공간 브랜딩', '가상 카메라로 연출하는 시네마틱 효과'][index]
                  : ['Infinite 3D objects & environments', 'Real-time creation and sharing', 'AI-powered spatial branding', 'Cinematic effect with a virtual camera'][index]
              }
              isHorizontal
            />
          ))}
        </FeatureBox.Contents>
      </FeatureBox>

      {/* Service Item Card */}
      <ServiceItemCard imgName="xroom_pro" title="" sub={servicesub} desc={serviceDesc}>
        <Button onClick={() => window.open('https://store.steampowered.com/app/2616930/XROOMLite/')}>
          {tryDemo}
        </Button>
      </ServiceItemCard>

      {/* Feature Box 2 */}
      <FeatureBox height={1034} FeatureBoxDesktop={FeatureBoxDesktop2}>
        <FeatureBox.Infos sub={assetsSub}>
          <FeatureBox.Title>{assetsTitle}</FeatureBox.Title>
          <FeatureBox.Details>{assetsDetails}</FeatureBox.Details>
        </FeatureBox.Infos>
        <FeatureBox.Contents>
          {['feature2_img1.png', 'feature2_img2.png', 'feature2_img3.png'].map((imgName, index) => (
            <ImgCard key={index} imgName={imgName} />
          ))}
        </FeatureBox.Contents>
      </FeatureBox>

      {/* Livestream and Interface Cards */}
      <Stack spacing={100}>
        {isMobile ? (
          <>
            <HorizontalCard className="livestream">
              <HorizontalCard.Desc sub={livestreamSub}>
                <HorizontalCard.Title>{livestreamTitle}</HorizontalCard.Title>
                <HorizontalCard.Details>{livestreamDetails}</HorizontalCard.Details>
              </HorizontalCard.Desc>
              <HorizontalCard.Img imgName="content_livestream" />
            </HorizontalCard>
            <HorizontalCard className="interface">
              <HorizontalCard.Desc sub={interfaceSub}>
                <HorizontalCard.Title>{interfaceTitle}</HorizontalCard.Title>
                <HorizontalCard.Details>{interfaceDetails}</HorizontalCard.Details>
              </HorizontalCard.Desc>
              <HorizontalCard.Img imgName="content_interface" />
            </HorizontalCard>
          </>
        ) : (
          <>
            <HorizontalCard className="livestream">
              <HorizontalCard.Img imgName="content_livestream" />
              <HorizontalCard.Desc sub={livestreamSub}>
                <HorizontalCard.Title>{livestreamTitle}</HorizontalCard.Title>
                <HorizontalCard.Details>{livestreamDetails}</HorizontalCard.Details>
              </HorizontalCard.Desc>
            </HorizontalCard>
            <HorizontalCard className="interface">
              <HorizontalCard.Desc sub={interfaceSub}>
                <HorizontalCard.Title>{interfaceTitle}</HorizontalCard.Title>
                <HorizontalCard.Details>{interfaceDetails}</HorizontalCard.Details>
              </HorizontalCard.Desc>
              <HorizontalCard.Img imgName="content_interface" />
            </HorizontalCard>
          </>
        )}
      </Stack>

      {/* Last Visual Section */}
      <LastVisualContainer>
        <Logo src="/images/xroom_ces/xroom_last.png" alt="xroom logo" />
        <h1>{lastVisualTitle}</h1>
        <p>{lastVisualDesc}</p>
        <ContactButton onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSeGqhJwxOVZaxUx4-iH0LbGmNTpqHCer8-4ZhWuJlQypdg9qw/viewform')}>
          {contactUs}
        </ContactButton>
      </LastVisualContainer>

      {/* Footer */}
      <Footer lang={lang} />
    </MainContainer>
  );
};

export default XroomSXSWDesktop;