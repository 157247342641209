import React from 'react';
import styled from 'styled-components';

// Styled components for the Footer
const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Pretendard';

  @media (min-width: 768px) {
    align-items: center;
    justify-content: center;
    padding: 60px 16px;
  }
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 15px;
  }
`;

const Logo = styled.div`
  margin-bottom: 20px;

  img {
    width: 150px;

    @media (max-width: 768px) {
      width: 120px;
    }
  }
`;

const Address = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FooterBottom = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column; /* Change to column */
  justify-content: space-between;
  font-size: 12px;
  border-top: 1px solid #333;
  padding-top: 15px;

  @media (min-width: 1024px) {
    flex-direction: row; /* Change back to row for larger screens */
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #83838C;

  a {
    margin: 0 6px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #fff; // Change color on hover
    }

    @media (max-width: 768px) {
      margin: 6px;
      font-size: 12px;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 10px;
    flex-wrap: wrap;
    max-width: 250px;
  }
`;

const Copyright = styled.div`
  text-align: left;
  margin-top: 10px;
  color: #83838C;

  @media (min-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

interface FooterProps {
  lang: string; // Modify this if you have a specific type for language
}

// Footer component
const Footer: React.FC<FooterProps> = ({ lang }) => {
  const links = [
    {
      href: "https://teamsoulx.notion.site/About-SOULX-dfd96103ce8b4b3dae42cc3d0a0f2331",
      text: lang === 'KO' ? '회사 소개' : 'Company Introduction',
    },
    {
      href: "https://www.youtube.com/@soulx2167",
      text: lang === 'KO' ? '유튜브' : 'YouTube',
    },
    {
      href: "#",
      text: lang === 'KO' ? '이용약관' : 'XROOM Terms of Service',
    },
    {
      href: "#",
      text: lang === 'KO' ? '개인정보 처리방침' : 'Privacy Policy',
    },
  ];

  return (
    <FooterContainer>
      <FooterTop>
        <Logo>
          <img src="/images/SoulxLogo_N.svg" alt="SoulX Logo" />
        </Logo>
        <Address>
          {lang === 'KO' ? (
            <>
              서울시 중구 청계천로 40 CKL기업지원센터 1203호 <br />
              (다동, 한국관광공사 서울센터)<br />
              전화: 010-3265-5641 | 사업자 등록 번호: 335-81-01340
            </>
          ) : (
            <>
              40, Cheonggyecheon-ro, Jung-gu, Seoul, Room 1203<br />
              (Da-dong, CKL BUSINESS CENTER)<br />
              Tel: 010-3265-5641 | Business Registration Number: 335-81-01340
            </>
          )}
        </Address>
      </FooterTop>
      <FooterBottom>
        <Links>
          {links.map((link, index) => (
            <React.Fragment key={index}>
              <a href={link.href} aria-label={link.text}>{link.text}</a>
              {index < links.length - 1 && <span>|</span>} {/* 마지막 링크 뒤에는 |를 추가하지 않음 */}
            </React.Fragment>
          ))}
        </Links>
        <Copyright>
          © 2023 SoulX.corp, All rights Reserved
        </Copyright>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
