import React from 'react';
import styled from 'styled-components';
import { Image } from '@chakra-ui/react';

// Headerbar styled component
const Headerbar = styled.nav`
  position: fixed; /* Fix the header at the top */
  width: 100%;
  height: 80px; /* Default height for desktop */
  top: 0;
  left: 0;
  display: flex;
  justify-content: center; /* Center alignment */
  align-items: center;
  background-color: #1A0C32;
  z-index: 1000; /* Ensure it stays on top of other content */

  @media (max-width: 1024px) {
    height: 80px; /* Adjust height for tablets */
  }

  @media (max-width: 768px) {
    height: 60px; /* Adjust height for mobile */
  }
`;

// HeaderBox styled component with responsive margins
const HeaderBox = styled.div`
  display: flex;
  align-items: center; /* Center alignment */
  justify-content: center; /* Center alignment */
  gap: 16px;
  flex: 1; /* Allow to fill available space */
  margin: 0 432px; /* Default margin for desktop */
  flex-wrap: nowrap; /* Prevent wrapping to keep in a single line */

  @media (max-width: 1024px) {
    margin: 0 167px; /* Adjust margin for tablets */
  }

  @media (max-width: 768px) {
    margin: 0 20px; /* Adjust margin for mobile */

  }
`;

// Styled component for text with truncation
const StyledText = styled.p`
  white-space: normal; /* Allow wrapping by default */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  font-family: Pretendard, sans-serif;
  color: white;
  font-size: 20px; /* Default font size for desktop */
  line-height: 25px;
  letter-spacing: 0.3px;
  text-align: center; /* Center the text */

  @media (max-width: 1024px) {
    font-size: 18px; /* Adjust font size for tablets */
    line-height: 22px; /* Adjust line height for tablets */
    max-width: calc(100% - 75px); /* Adjust max-width to accommodate the image */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Smaller font size for mobile */
    line-height: 20px; /* Adjust line height for mobile */
    max-width: calc(100% - 60px); /* Limit maximum width to prevent overflow */
    white-space: nowrap; /* Prevent wrapping in mobile */
  }
`;

// Styled component for responsive image
const StyledImage = styled(Image)`
  width: 89px; /* Default width for desktop */
  height: 79px; /* Default height for desktop */

  @media (max-width: 1024px) {
    width: 75px; /* Adjust width for tablets */
    height: 65px; /* Adjust height for tablets */
  }

  @media (max-width: 768px) {
    width: 60px; /* Smaller width for mobile */
    height: 55px; /* Smaller height for mobile */
  }
`;

// Header component
const Header: React.FC<{ language: string }> = ({ language }) => {
  const headerText =
    language === 'EN'
      ? 'The 2024 Edison Awards Winner for ‘Innovative Entertainment & Design Technology’'
      : '소울엑스, 미국 최고 발명상 ‘Edison Awards 2024’ 혁신적인 엔터테인먼트 & 디자인 기술 부문 수상';

  return (
    <Headerbar id="mainHeader">
      <HeaderBox id="HeaderBox">
        <StyledImage
          src="/images/Header/EdisonAwards.png"
          alt="Edison Awards"
        />
        <StyledText>
          {headerText}
        </StyledText>
      </HeaderBox>
    </Headerbar>
  );
};

export default Header;
