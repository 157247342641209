import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react';

import Main from 'pages/soulx/main';
import XroomSXSW from 'pages/soulx/XroomSXSW';
import NotFoundPage from 'pages/NotFoundPage';
import ScrollToTop from 'components/scrolltotop';
import RouteChangeTracker from 'components/routeChangeTracker';
import Core from 'containers/base/Core';
import { JazzbarProvider } from 'lib/jazzbar';
import ConditionalBackground from 'components/base/ConditionalBackground';
import ErrorBoundary from 'components/error/ErrorBoundary';
import { theme } from './lib/styles/chakraTheme';

const manager = createLocalStorageManager('my-key');

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const App: React.FC = () => {
  const [language, setLanguage] = useState<string>('EN'); // Default language

  return (
    <ChakraProvider theme={theme} colorModeManager={manager}>
      <JazzbarProvider>
        <Helmet>
          <title>Soulx</title>
          <meta name="description" content="안녕하세요 소울 엑스 입니다." />
          <meta property="og:image" content="https://images.soulx.co.kr/soulx.png" />
        </Helmet>
        <AppContainer>
          <ConditionalBackground />
          <RouteChangeTracker />
          <ScrollToTop />
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/XROOM/*" element={<XroomSXSW language={language} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ErrorBoundary>
          <Core />
        </AppContainer>
      </JazzbarProvider>
    </ChakraProvider>
  );
};
export default App;
