// import * as react from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './typography.css';
import { composeWithDevTools } from '@redux-devtools/extension';
import { Provider } from 'react-redux';
import rootReducer from './modules';
import storage from './lib/storage';
import { setUser } from './modules/core';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';
import darkMode from './modules/darkmode';
import reportWebVitals from './reportWebVitals';
import { app } from './firebase';
import { createStore } from 'redux';


const store = createStore(
  rootReducer,
  (window as any).__REDUX_STATE__,
  composeWithDevTools(),
);
const loadUser = () => {
  const user = storage.getItem('CURRENT_USER');
  if (!user) return;
  store.dispatch(setUser(user));
};
const loadTheme = () => {
  const theme = storage.getItem('theme');
  if (!theme) return;
  if (theme === 'dark') {
    store.dispatch(darkMode.actions.enableDarkMode());
  } else {
    store.dispatch(darkMode.actions.enableLightMode());
  }
  document.body.dataset.theme = theme;
};

loadUser();
loadTheme();
const contains = document.getElementById('root')!;
const root = ReactDOM.createRoot(contains);

if (process.env.NODE_ENV === 'production') {
  loadableReady(() => {
    ReactDOM.hydrateRoot(
      contains,
      <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
              <App></App>
            </BrowserRouter>
        </Provider>
      </HelmetProvider>,
    );
  });
} else {

  root.render(
    
    <HelmetProvider>
      <Provider store={store}>
          <BrowserRouter>
            <App></App>
          </BrowserRouter>
      </Provider>
    </HelmetProvider>,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
