import React from 'react';
import styled from 'styled-components';
import { Box, Icon } from '@chakra-ui/react';

// Styled component for the footer
const FooterContainer = styled.div`
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 85px; // 여백 조정
  font-family: Pretendard;
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 466px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 96px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.3px;

  @media (max-width: 1024px) {
    font-size: 64px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  max-width: 400px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 17px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const IconBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 142px;

  @media (max-width: 1024px) {
    margin-top: 71px;
  }

  @media (max-width: 768px) {
    margin-top: 64px;
  }
`;

const StyledIcon = styled(Icon)`
  color: white;
  font-size: 24px;
  margin: 0 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const CopyrightText = styled.p`
  font-size: 16px;
  margin-top: 44px;
  color: rgba(255, 255, 255, 0.7);

  @media (max-width: 1024px) {
    margin-top: 28px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 12px;
  }
`;

const BottomMargin = styled.div`
  margin-top: 108px;

  @media (max-width: 1024px) {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const Footer: React.FC<{ language: string }> = ({ language }) => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Title>SOULX</Title>
        <Text>
          {language === 'EN'
            ? 'Guaranteeing the freedom of space and expression to all storytellers in the world'
            : '세상의 모든 스토리텔러들에게 공간과 소통의 자유를 주다.'}
        </Text>
      </FooterWrapper>
      <IconBox>
        <a href="https://www.linkedin.com/company/teamsoulx/" target="_blank" rel="noopener noreferrer">
          <StyledIcon as="img" src="/images/Linkdin_ICON.svg" alt="LinkedIn" />
        </a>
        <a href="https://www.instagram.com/studio.xroom/" target="_blank" rel="noopener noreferrer">
          <StyledIcon as="img" src="/images/Instargram.svg" alt="Instagram" />
        </a>
        <a href="https://www.youtube.com/@soulx2167" target="_blank" rel="noopener noreferrer">
          <StyledIcon as="img" src="/images/youtube_ICON.svg" alt="YouTube" />
        </a>
      </IconBox>
      <CopyrightText>© 2024 SOULX. All rights reserved.</CopyrightText>
      <BottomMargin />
    </FooterContainer>
  );
};

export default Footer;
