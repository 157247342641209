import React, { useState } from 'react';
import styled from 'styled-components';
import Navigation from 'components/soulx/Navigation';
import Header from 'components/soulx/header';
import Video from 'components/soulx/Video';
import Footer from 'components/soulx/Footer';

// Main container styled component
const MainContainer = styled.div`
  display: flex;  // Ensure it's a flex container
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url('/images/Back.png'); // Add your background image path here
  background-size: cover; // Cover the entire container
  background-position: center; // Center the image
  min-height: 100vh; // Ensure it covers the full height of the viewport
  overflow: hidden; // Prevents horizontal scroll
`;

const Spacer = styled.div`
  margin-top: 108px; // Adjust this value to create enough space below the navbar
  margin-right: 25px; // Adjust this value to create enough space below the navbar
  display: flex; // Allow content to be aligned
  justify-content: flex-end; // Align items to the right
  width: 100%; // Take the full width

  // Tablet and larger screens
  @media (max-width: 1024px) {
    font-size: 16px; // Adjust font size for tablets
  }

  // Mobile screens
  @media (max-width: 768px) {
    margin-top: 76px; // Reduce margin on mobile
    margin-right: 20px; // Adjust this value to create enough space below the navbar
    justify-content: flex-end; // Keep content aligned to the right on mobile
  }
`;

// Styled component for language toggle button
const LangButton = styled.button`
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-family: Pretendard;
  margin: 0; // Remove additional margin for better alignment
  transition: color 0.3s ease, background-color 0.3s ease;
  text-decoration: underline; // Underline to mimic a hyperlink

  // Tablet and larger screens
  @media (max-width: 1024px) {
    font-size: 16px; // Adjust font size for tablets
  }

  // Mobile screens
  @media (max-width: 768px) {
    font-size: 14px; // Further adjust font size for mobile
  }
`;

// Styled component for the logo
const Logo = styled.img`
  margin-top: 20px; // Fixed to lowercase 'm'
  width: 132px; // Default width for desktop
  height: 165px; // Maintain aspect ratio
  display: flex; // Allow content to be aligned
  margin-bottom: 44px; // Fixed to lowercase 'm'
  // Tablet screens
  @media (max-width: 1024px) {
    margin-top: 28px; // Fixed to lowercase 'm'
    width: 119px; // Default width for tablet
    height: 148px; // Maintain aspect ratio'
    margin-bottom: 58px; // Fixed to lowercase 'm'
  }

  // Mobile screens
  @media (max-width: 768px) {
    margin-top: 20px; // Fixed to lowercase 'm'
    width: 61px; // Default width for mobile
    height: 76px; // Maintain aspect ratio
    margin-bottom: 36px; // Fixed to lowercase 'm'
  }
`;

const Main: React.FC = () => {
  const [language, setLanguage] = useState('EN'); // 기본 언어는 영어로 설정

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === 'EN' ? 'KO' : 'EN'));
  };

  return (
    <MainContainer id="Main">
      <Header language={language} />
      <Spacer>
        <LangButton onClick={toggleLanguage}>
          {language === 'EN' ? 'KO' : 'EN'}
        </LangButton>
      </Spacer>
      <Logo src="/images/soulx.svg" alt="Logo" /> {/* Add your logo path here */}
      <Navigation language={language} />
      <Video language={language} />
      <Footer language={language} />
    </MainContainer>
  );
};

export default Main;
