import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // React Router 사용

// Styled component for the navigation bar
// Styled component for the navigation bar
const NavBar = styled.nav`
  width: 100%;
  height: 80px;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin-top: 20px;
  margin-bottom: 56px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative; // Positioning added
  z-index: 10; // Ensure the navbar appears above the video

  @media (max-width: 1024px) {
    margin-top: 15px;
    margin-bottom: 48px;
  }

  @media (max-width: 768px) {
    height: 260px;
    margin-top: 10px;
    margin-bottom: 200px; // Video와의 간격을 위해 margin을 충분히 추가
  }
`;


// Flex container for navigation links
const NavLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    justify-content: space-around;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

// Base styles for navigation links
const BaseNavLink = styled(Link)`
  color: white;
  border-radius: 50px;
  border: 1px solid #b1b2bc;
  padding: 9px 22px;
  font-family: Pretendard;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  background-color: transparent;
  letter-spacing: -0.2px;

  &:hover {
    color: #cebdff;
  }

  &:active {
    animation: shine 0.3s forwards;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 48px;
    margin: 10px 0;
  }

  @keyframes shine {
    0% {
      background-color: #6b6c74;
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: transparent;
    }
  }
`;

// Specific style for the XROOM navigation link
const XRoomLink = styled(BaseNavLink)`
  background-color: white;
  color: black;

  &:hover {
    color: #000;
  }
`;

const LogoImage = styled.img`
  width: 20px;
  height: auto;
  margin-left: 8px;
`;

// Navigation component
const Navigation: React.FC<{ language: string }> = ({ language }) => {
  return (
    <NavBar>
      <NavLinksContainer>
        <BaseNavLink to="https://teamsoulx.notion.site/About-SOULX-dfd96103ce8b4b3dae42cc3d0a0f2331">
          {language === 'EN' ? 'About Soulx' : '소개'}
        </BaseNavLink>
        <BaseNavLink to="https://teamsoulx.notion.site/Soulx-Service-16dad1ef709a4eebaf1ca2983e2b4346?pvs=74">
          {language === 'EN' ? 'Services' : '서비스'}
        </BaseNavLink>
        <BaseNavLink to="https://teamsoulx.notion.site/SOULX-Portfolio-bb8555ff22274a5e96ef220f5b248398">
          {language === 'EN' ? 'Portfolios' : '포트폴리오'}
        </BaseNavLink>

        <XRoomLink to={`/XROOM?lang=${language}`}>
          {language === 'EN' ? 'Meet XROOM' : 'Meet XROOM'}
          <LogoImage src={`${process.env.PUBLIC_URL}/images/Icon Right.svg`} alt="Logo" />
        </XRoomLink>

        <BaseNavLink to={language === 'EN' ? 
          'https://teamsoulx.notion.site/XRoom-Zero-23bee4a9c3164ff488ea0906d7ab6cb1?pvs=4' : 
          'https://teamsoulx.notion.site/XRoom-Zero-b622aa78a3424d7381786b8d36ff0649?pvs=4'}>
          {language === 'EN' ? 'XROOM Zero' : 'XROOM Zero'}
        </BaseNavLink>
      </NavLinksContainer>
    </NavBar>
  );
};

export default Navigation;
